import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Coming Soon</title>
      <h1>
        Coming Soon!
      </h1>
    </main>
  )
}

export default IndexPage
